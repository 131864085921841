import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import Versed from "../../images/projects/versed/cover.jpg"
import Labgrown from "../../images/projects/labgrown/cover.jpg"


const aurisPage = () => (
  <Layout>
    <SEO title="SIN Ceramics" />
    <WorkInfo 
      title="SIN Ceramics"
      link="virginiasin.com"
      team1="Bailey Latimer"
      team2="Angela Zhou"
      challenge="Building a minimalist site for the Brooklyn-based, handmade ceramics and home goods brand."
      solution="Building a minimalist site for the Brooklyn-based, handmade ceramics and home goods brand."/>

    <StaticImage class="mb-6" src="../../images/projects/sin/cover.jpg" alt="SIN Ceramics Hero" aspectRatio={16/7} layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/sin/browser.jpg" alt="SIN Ceramics Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/sin/product--mobile.jpg" alt="SIN Ceramics Product Page" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/sin/pdp--mobile.jpg" alt="SIN Ceramics About Mobile" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-20" src="../../images/projects/sin/wishlist--mobile.jpg" alt="SIN Ceramics Wishlist Mobile" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <div class="adv-gallery-v3 mb-20">
      <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>

      <ul class="columns-1 lg:columns-2">
        <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

        <ProjectCard classes="" title="Labgrown" url="labgrown"  description="Creating an e-commerce experience to position labgrown as a pioneer in the sustainable jewelry market." cover={Labgrown}  role="Design + Development + Branding"/>
      </ul>
    </div>

  </Layout>
)

export default aurisPage
